<template>
  <div class="content-container minSize">
    <!-- 头部 -->
    <div class="head">
      <div class="left">
        <div class="logo">
          <img src="@/assets/images/menu/nav_logo.svg" />
        </div>
        <div class="title">{{ $t('common.title') }}</div>
      </div>

      <div class="menu">
        <div
          class="index"
          :class="[activeMenu==='index' ? 'active' : '']"
          @click="handleMenu('index')"
          :title="$t('home.index')"
        >
          <img
            :src="activeMenu==='index' ? require('@/assets/images/menu/index_green.png') : require('@/assets/images/menu/index1.png')"
            alt
            class='index-img'
          />
        </div>
        <div
          class="meeting"
          :class="[activeMenu==='meeting' ? 'active' : '']"
          @click="handleMenu('meeting')"
          :title="$t('home.meetRecord')"
        >
          <img
            :src="activeMenu==='meeting' ? require('@/assets/images/menu/list_active.svg') : require('@/assets/images/menu/list.svg')"
            alt
          />
        </div>
      </div>
      <div class="personal-info">
        <div class="system">
          <ul class="system-list">
            <li class="system-item">
              <meetingTopRightUploadLog />
            </li>
            <li class="system-item" @click="help">
              <my-icon class="myIcon" fontSize="16" iconName="icondaohanglan_bangzhu_bai"></my-icon>{{$t('seeting.help')}}
            </li>
            <li class="system-item" @click="service">
              <my-icon class="myIcon" fontSize="16" iconName="icondaohanglan_kefu_bai"></my-icon>{{$t('seeting.service')}}
            </li>
          </ul>
        </div>
          <div class="avatar">
          <img v-if="userInfo.avatar"  :title="$t('home.personMeet')" :src="userInfo.avatar" class="top-bar-avatar iconhuiyixinxi" @click="handlePersonal"/>
          <my-icon v-else class="iconhuiyixinxi" :iconName="'icontouxiang_40_lv'" @click="handlePersonal"></my-icon>
          <transition name="fade-in-linear">
            <Personal
              v-if="showPersonal"
              v-extend-panel-manager="onClosePersonal"
              @handleLoginOut="handleLoginOut"
              :userInfo="userInfo"
              @onEditUserName="onEditUserName"
              @onEditPwd="onEditPwd"
              @onMore="onMore"
            ></Personal>
          </transition>
        </div>
        <Lang />
      </div>
    </div>

    <!-- 返回 -->
    <Back v-show="reservation" @handleBack="handleBack"></Back>

    <!-- 内容 -->
    <div class="content">
      <!-- 今日会议 -->
      <div class="meeting-list" v-show="activeMenu==='index'&& !reservation">
        <div class="meeting-bg">
          <!-- <img class='xt_style' src="@/assets/images/xticon.png" alt=""> -->
          <div class="time">{{ nowTime }}</div>
          <div class="calendar">{{ nowCalendar }}</div>
        </div>
        <div class="meeting-container-main">
          <div class="meeting-jump-top" v-show="jumpTopCount>1">{{jumpText}}</div>
          <div class="meeting-container"  v-loading="isLoadingToday && meetingList.length === 0">
            <ul class="meeting-item-container" v-if="meetingList.length"
                infinite-scroll-immediate="false"
                infinite-scroll-disabled="scrollTodayDisabled"
                v-infinite-scroll="loadMeetingList.bind(this, 'index', true)">
              <li
                class="meeting-item"
                v-for="(item, index) in meetingList"
                :key="item.conferenceNo"
              >
                <div class="meeting-detail meeting-detail1">
                  <div class="meeting-title" v-extend-max-length="[16, '...', item.title]"></div>
                  <div class="meeting-time">
                    <div>
                      {{ formatAMPM(item.beginTime, item.endTime) }}
                      <span class="tips">{{item.statusText}}</span>
                    </div>
                  </div>
                  <div class="meeting-code">{{$t('login.meetNumber')}}{{ $tools.formatCode(item.conferenceNo) }}</div>
                </div>

                <div class="meeting-begin meeting-detail2">
                  <div class="more">
                    <a href="javascript:;" @click="handlePanel(item, index)">
                      <img src="@/assets/images/menu/more.svg" alt class="more_button" />
                      <img
                        src="@/assets/images/menu/more_hover.svg"
                        alt
                        class="more_button-hover"
                      />
                      <img
                        src="@/assets/images/menu/more_active.svg"
                        alt
                        class="more_button-active"
                      />
                    </a>
                    <div
                      class="list-panel"
                      v-show="panelIndex===index"
                      v-extend-panel-manager="closePanel"
                    >
                      <ul class="list-panel-container">
                        <li
                          class="list-panel-item"
                          v-clipboard:copy="item.links"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError"
                        >{{$t('seeting.copyINfo')}}</li>
                        <li
                          class="list-panel-item"
                          v-if="item.status === 0"
                          @click="handleReservation(item.id)"
                        >{{$t('seeting.edit')}}</li>
                        <li class="list-panel-item" @click="deleteItem(item, 1)">{{$t('seeting.delete')}}</li>
                      </ul>
                    </div>
                  </div>
                    <el-button  class="begin-button" @click="handleBegin(item)">{{$t('seeting.goMeet')}}</el-button>
                </div>
                <div class="border"></div>
              </li>
            </ul>
            <div class="tips" v-if="!isLoadingToday && meetingList.length==0">{{$t('seeting.meetTips')}}</div>
          </div>
          <div
            class="meeting-jump"
            v-show="jumpCount>0"
            @click="handleMenu('meeting')"
          >{{$t('seeting.lookMeet')}}({{jumpCount}})</div>
        </div>
      </div>
      <!-- 按钮区 -->
      <div class="meeting-button" v-show="activeMenu==='index' && !reservation">
        <div class="button-item">
          <div class="wrapButItem">
            <a href="javascript:;" class="button-group" @click="handleJoinMeeting">
              <img src="../../assets/images/menu/new.svg" alt class="button-bg" />
              <img src="../../assets/images/menu/new_hover.svg" alt class="button-bg-hover" />
              <img src="../../assets/images/menu/new_active.svg" alt class="button-bg-active" />
              <p style='width:90px'>{{$t('seeting.newMeet')}}</p>
            </a>
            <my-icon
              :class="['arrowIcon1', isShowPanel ? 'arrowRotate' : '']"
              :target="'layoutArrowIcon1'"
              :fontSize="16"
              :iconName="'iconjiantou_xiajiantou_hei'"
              @click="click"
            ></my-icon>

            <div class="selectPanel" v-show="isShowPanel" v-extend-panel-manager="onClose">
              <div class="cbItem2">
                <el-checkbox  v-model="myCheckbox">{{$t('login.openMicrophone')}}</el-checkbox>
              </div>
              <div class="cbItem2">
                <el-checkbox  v-model="loudspeaker">{{$t('login.openSpeaker')}}</el-checkbox>
              </div>
              <div class="cbItem2">
                <el-checkbox  v-model="myCamera">{{$t('login.openCamera')}}</el-checkbox>
              </div>
              <div class="cbItem2">
                <el-checkbox  v-model="myGeRen">{{userSelfNo}}</el-checkbox>
              </div>
            </div>
          </div>
          <a href="javascript:;" class="button-group" @click="$router.push({ name: 'join' })">
            <img src="../../assets/images/menu/join.svg" alt class="button-bg" />
            <img src="../../assets/images/menu/join_hover.svg" alt class="button-bg-hover" />
            <img src="../../assets/images/menu/join_active.svg" alt class="button-bg-active" />
            <p>{{$t('home.joinMeeting')}}</p>
          </a>
        </div>
        <div class="button-item">
          <a href="javascript:;" class="button-group" @click="handleReservation('')">
            <img src="../../assets/images/menu/reservation.svg" alt class="button-bg" />
            <img src="../../assets/images/menu/reservation_hover.svg" alt class="button-bg-hover" />
            <img src="../../assets/images/menu/reservation_active.svg" alt class="button-bg-active" />
            <p>{{$t('login.bookMeet')}}</p>
          </a>
          <a href="javascript:;" class="button-group" @click="openSetting">
            <img src="../../assets/images/menu/setting.svg" alt class="button-bg" />
            <img src="../../assets/images/menu/setting_hover.svg" alt class="button-bg-hover" />
            <img src="../../assets/images/menu/setting_active.svg" alt class="button-bg-active" />
            <p>{{$t('login.indexSet')}}</p>
          </a>
        </div>
      </div>
      <!-- 预约会议与历史会议 -->
      <div class="history" v-show="activeMenu==='meeting'&& !reservation">
        <div class="history-list">
          <div class="meeting-tab">
            <div class="tab-button">
              <el-button
                :type="appointment ? 'primary' : ''"
                size="small"
                class="appointment"
                @click="handleMeetingList('appointment')"
              >{{$t('login.bookMeet')}}</el-button>
              <el-button
                :type="appointment ? '' : 'primary'"
                size="small"
                class="history-button"
                @click="handleMeetingList('history')"
              >{{$t('seeting.oldMeet')}}</el-button>
            </div>
            <div class="meeting-add" :title="$t('login.bookMeet')" @mouseover="hover = true" @mouseleave="hover = false" @mousedown='isfocus = true' @mouseup='onMouse'>
              <my-icon fontSize="32" :iconName="!hover ? 'iconxinzeng_30_lv' : isfocus ? 'iconxinzeng_30_lv_anxia' : 'iconxinzeng_30_lv_jingguo'" ></my-icon>
            </div>
          </div>
          <div class="meeting-code" v-show="appointment" >
            <ul @click="myCode">
              <li>{{ meetingCode }}</li>
              <li>{{$t('seeting.mySelfMeet')}}</li>
            </ul>
          </div>
          <div class="meeting-history-list" style="transform:translate3d(0,0,0)"  v-show="meetingHistoryList.length>0" v-loading='isLoading'>
            <ul class="meeting-info"
                v-infinite-scroll="loadMeetingList.bind(this, '', true)"
                infinite-scroll-immediate="false"
                infinite-scroll-disabled="scrollDisabled">
              <li
                class="infoItem"
                v-for="(item, index) in meetingHistoryList"
                :key="item.conferenceDetailId"
                @click="handleMeetingDetail(item, index)"
              >
                <div class="date" v-show="isShowTitle(appointment ? item.beginTime : item.enterTime, index)">{{getTitleDate(appointment ? item.beginTime : item.enterTime)}}</div>
                <div class="meeting-detail" :class="{'current': rowIndex === index}">
                  <div class="meeting-title meet-width"  v-extend-max-length="[20, '...', item.title]"></div>
                  <div class="meeting-date" v-if="appointment">{{ formatAMPM(item.beginTime, item.endTime) }}</div>

                  <!-- <div class="meeting-date" v-else>入会时间: {{ getEnterDate(item) }}</div> -->
                  <div class="meeting-owner" v-else>{{$t('seeting.firstPerson')}}<span  v-extend-max-length="[20, '...', item.ownerName]"></span></div>
                  <div class="meeting-detail-code">{{$t('login.meetNumber')}}{{ $tools.formatCode(item.conferenceNo) }}</div>
                  <!-- <div class="meeting-ren"  v-if="appointment">
                    发起人:
                    <span v-extend-max-length="[5, '...', item.ownerName]"></span>
                  </div> -->
                </div>
              </li>
            </ul>
          </div>
          <div
            class="nothingYuYue"
            v-if="!isLoading && meetingHistoryList.length==0"
          >{{$t('seeting.no')}}{{appointment ? $t('seeting.yy'): $t('seeting.ls')}} {{$t('seeting.meet')}}</div>
        </div>
        <div class="history-detail" v-loading='isLoading'>
          <div class="personal-info" v-if="detailType==='personal'">
            <template v-if="appointment">
              <div class="title2">{{$t('seeting.mySelfMeet')}}</div>
              <div class="meeting-code">{{ meetingCode }}</div>
              <div class="button-link">
                <el-button type="primary" size="small" @click="handleBegin({ conferenceNo: meetingCode })">{{$t('seeting.goMeet')}}</el-button>
                <el-button
                  size="small"
                  v-clipboard:copy="userInfo.links"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  class='btn_style'
                >{{$t('seeting.copyINfo')}}</el-button>
                <el-button size="small" class='btn_style'  @click="onMore">{{$t('seeting.edit')}}</el-button>
              </div>
            </template>
          </div>
          <div class="meeting-info" v-else>
            <div class="title">{{meetingDetail.title}}</div>
            <div class="hisTitleB" v-if="!appointment">{{meetingDetail.status == 0 ? $t('seeting.meetEnd') : $t('seeting.meetEnd') }}</div>
            <!-- 预约会议信息 -->
            <ul class="subscribe" v-if="isShowDetail">
              <li class="subscribe-item time">
                {{ formatAMPM(meetingDetail.beginTime, meetingDetail.endTime) }}

                <span
                  class="status"
                >{{ getYuYueState(meetingDetail) }}</span>
              </li>
              <li
                class="subscribe-item meeting-code"
              >{{$t('meeting.meetID')}}：{{ $tools.formatCode(meetingDetail.conferenceNo) }}</li>
              <li class="subscribe-item meeting-password" v-if="meetingDetail.password">{{$t('login.meetPsd')}}{{ meetingDetail.password }}</li>
              <li class="subscribe-item host-password" v-if="meetingDetail.ownerPassword">{{$t('login.mainPwd')}}{{ meetingDetail.ownerPassword }}</li>
              <li class="button-link">
                <el-button type="primary" size="small" @click="handleBegin(meetingDetail)">{{$t('seeting.goMeet')}}</el-button>
                <el-button
                  size="small"
                  v-clipboard:copy="meetingDetail.links"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  class='btn_style'
                >{{$t('seeting.copyINfo')}}</el-button>
                <el-button
                  size="small"
                  v-if="meetingDetail.status === 0"
                  @click="handleReservation(meetingDetail.id)"
                  class='btn_style'
                >{{$t('seeting.edit')}}</el-button>
                <el-button class='red_style' size="small" type="warning" plain @click="handleCancelMeeting">{{$t('seeting.delete')}}</el-button>
              </li>
            </ul>
            <!-- 历史会议信息 -->
            <ul class="history" v-if="!isShowDetail">
              <li class="history-item">
                <span class="history-item-left">{{$t('seeting.startMeet')}}</span>
                <span class="history-item-right">{{ $dateFormat(meetingDetail.enterTime, 'yyyy-MM-dd hh:mm') }}</span>
              </li>
              <li class="history-item">
                <span class="history-item-left">{{$t('seeting.endMeet')}}</span>
                <span class="history-item-right">{{ $dateFormat(meetingDetail.exitTime, 'yyyy-MM-dd hh:mm') }}</span>
              </li>

              <!-- <li class="history-item">
                <span>入会时间：</span>
                <span>{{ $dateFormat(meetingDetail.enterTime, 'yyyy年MM月dd日 hh:mm') }}</span>
              </li>
              <li class="history-item">
                <span>退会时间：</span>
                <span>{{ $dateFormat(meetingDetail.exitTime, 'yyyy年MM月dd日 hh:mm') }}</span>
              </li>
              <li class="history-item">
                <span>参会时长：</span>
                <span>{{ $formatSeconds(meetingDetail.duration) }}</span>
              </li>-->
              <li class="history-item border"></li>
              <li class="history-item">
                <span class="history-item-left">{{$t('login.meetNumber')}}</span>
                <span class="history-item-right">{{ $tools.formatCode(meetingDetail.conferenceNo) }}</span>
              </li>

              <li class="history-item" v-if="meetingDetail.password">
                <span class="history-item-left">{{$t('login.meetPsd')}}</span>
                <span class="history-item-right">{{ meetingDetail.password }}</span>
              </li>
              <li class="history-item" v-if="meetingDetail.ownerPassword">
                <span class="history-item-left">{{$t('login.mainPwd')}}</span>
                <span class="history-item-right">{{ meetingDetail.ownerPassword }}</span>
              </li>

              <li class="history-item">
                <span class="history-item-left">{{$t('seeting.firstPerson')}}</span>
                <span class="history-item-right">{{ meetingDetail.ownerName }}</span>
              </li>
              <li class="history-item">
                <span class="history-item-left">{{$t('seeting.joinPerson')}}</span>
                <span class="export history-item-right" @click="onDownload(meetingDetail)">{{$t('seeting.excelPerson')}}</span>
              </li>
              <li class="button-link">
                <el-button size="small" v-if="meetingDetail.type === 1 && meetingDetail.status != 50" type="primary" @click="handleBegin(meetingDetail)">{{$t('seeting.joinAgain')}}</el-button>
                <el-button size="small" type="danger" plain @click="handleDel">{{$t('seeting.delete')}}</el-button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Book
        v-if="reservation"
        :meetingId="meetingDetailId"
        :userInfo="userInfo"
        @handleCancel="handleCancel"
        @handleSave="handleSave"
        @handleUpdate="handleUpdate"
      ></Book>
    </div>
    <!-- 页脚 -->
    <Footer></Footer>
    <!-- 修改用户密码 -->
    <el-dialog
      :title="$t('seeting.changePwd')"
      :visible.sync="UserPwdtDialog"
      width="27%"
      >
      <el-input  v-model="myUserPwd.value" class='input_pwd' :placeholder="$t('seeting.inputOldPwd')" :minlength='6' :maxlength = "16" clearable show-password></el-input>
      <el-input  v-model="myUserNewPwd.value" class='input_pwd' :placeholder="$t('seeting.inputNewPwd')" :minlength='8' :maxlength = "16" clearable show-password></el-input>
      <el-input  v-model="myUserNewPwd2.value" class='input_pwd' :placeholder="$t('seeting.inputAgainPwd')" :minlength='8' :maxlength = "16" clearable show-password></el-input>
      <div class="errorTip errorTip2">{{myUserPwd.errorTip}}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="UserPwdtDialog = false">{{$t('login.cancel')}}</el-button>
        <el-button type="primary" @click="onAffirmUserPwd">{{$t('login.sure')}}</el-button>
      </span>
    </el-dialog>

    <!-- 修改会议弹窗 -->
    <el-dialog
      :visible.sync="changeMeetDialog"
      width="37%"
      >
      <div class="updateMoreTitle">{{$t('seeting.setMeet')}}</div>
      <div class="gerenTitle">{{$t('seeting.mySelfMeet')}}</div>
      <div class="gerenValue">{{$tools.formatCode(userInfo.conferenceNo)}}</div>
      <div class="huiyiPwdTitle">{{$t('login.meetPsd')}}</div>
      <div class="huiyiItemWrap">
        <div class="huiyiItem">
        <el-checkbox class='huiyiPwdCheck' @change="onChangePwd(0)"  v-model="password">{{$t('login.setMeetPwd')}}</el-checkbox>
        <el-input class='input_style pwd'  v-show="password" v-model.trim="huiyiPwd.value"  :placeholder="$t('login.meetNo')" :maxlength = "6" clearable show-password></el-input>
        </div>
        <div class="huiyiItem">
        <el-checkbox class='huiyiPwdCheck' @change="onChangePwd(1)"  v-model="ownerPassword">{{$t('login.setMainPwd')}}</el-checkbox>
        <el-input class='input_style ownerPwd'  v-show="ownerPassword" v-model.trim="huiyiOwnerPwd.value"  :placeholder="$t('login.onlyNo')" :maxlength = "6" clearable show-password></el-input>
        </div>
        <div class="huiyiItem">
          <el-checkbox class='huiyiCheck2'  v-model="allowEarlyEntry">{{$t('login.joinMeetok')}}</el-checkbox>
        </div>
        <div class="huiyiItem">
        <el-checkbox class='huiyiCheck2'  v-model="muteJoinMeeting">{{$t('login.mute')}}</el-checkbox>
        </div>
      </div>
     
      <div class="errorTip errorTip2">{{huiyiPwd.errorTip || huiyiOwnerPwd.errorTip}}</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeMeetDialog = false">{{$t('login.cancel')}}</el-button>
        <el-button type="primary" @click="onAffirmMore">{{$t('login.sure')}}</el-button>
      </span>
    </el-dialog>

    <!-- 会议设置弹窗 -->
    <meetingSettings ref="settingEl"></meetingSettings>
    <!-- 删除会议弹窗 -->
    <el-dialog
      custom-class="commonElDialog"
      width="380px"
      :visible.sync="delMeetElDialog"
    >
      <div class="dialog-title">
        <div class="icon error"></div>
        <div class="text">{{$t('seeting.deleteMeet')}}</div>
      </div>
      <div class="dialog-content">
        {{ delMeetElDialogText }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="delMeetType === 1" :loading="delMainMeetLoading"  type="primary" @click="delMainMeetHandle">{{ delMainMeetLoading ? $t('seeting.deleting') : $t('seeting.delete') }}</el-button>
        <el-button v-if="delMeetType === 2" :loading="delReservationMeetLoading" type="primary" @click="delReservationMeetHandle">{{ delReservationMeetLoading ? $t('seeting.deleting') : $t('seeting.delete')}}</el-button>
        <el-button v-if="delMeetType === 3" :loading="delHistoryMeetLoading" type="primary" @click="delHistoryMeetHandle">{{ delHistoryMeetLoading ? $t('seeting.deleting') : $t('seeting.delete')}}</el-button>
        <el-button @click="delMeetElDialog = false">{{$t('login.cancel')}}</el-button>
      </span>
    </el-dialog>

    <!-- 修改密码 -->
    <el-dialog
      custom-class="commonElDialog"
      width="380px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible="passwordDialog"
      @close="passwordDialogCloseHandle"
    >
      <div class="dialog-title" style="marginBottom: 15px;">
        <div class="text">
          {{ $t('login.pleasePws') }}
        </div>
      </div>

      <el-form :model="passwordForm" :rules="passwordRules" ref="passwordForm">
        <el-form-item prop="pwd">
          <el-input :placeholder="$t('login.meetNo')" clearable v-model="passwordForm.pwd"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="passwordDialog = false">
          {{ $t('login.cancel') }}
        </el-button>

        <el-button type="primary" @click="passwordSubmit">
          {{ $t('login.sure') }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 是否可以预约会议和个人会议提前进入 -->
    <el-dialog
      custom-class="advanceElDialog"
      :width = "$i18n.locale === 'en-US' ? '570px' : '420px'"
      :visible.sync="advanceDialog"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="advanceDialogCloseHandle"
    >
      <div class="axiosTitle">{{ advanceErrorData.content }}</div>

      <div class="axiosItem" v-if="advanceErrorData.beginTime && advanceErrorData.type === 1">
        <div class="axiosItemTitle">{{ $t('login.startTime') }}</div>
        <div class="axiosItemValue">{{ advanceErrorData.beginTime }}</div>
      </div>

      <div class="axiosItem">
        <div class="axiosItemValue mutRowsTitle">{{ advanceErrorData.title }}</div>
      </div>
      <div class="axiosItem">
        <div class="axiosItemValue2">{{$t('login.ifMain')}}</div>
      </div>

      <span slot="footer" class="dialog-footer" style='display:inline-block'>
        <el-button @click="goLoginPage">{{$t('home.signIn')}}</el-button>
        <el-button type="primary" @click="advanceDialog = false">{{$t('login.leaveMeet')}}</el-button>
      </span>
    </el-dialog>

    <!-- 主持人正在开启另外的会议 -->
    <el-dialog
      custom-class="commonElDialog"
      width="380px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible="waitHostDialog"
      @close="waitHostDialogCloseHandle"
    >
      <div class="dialog-title">
        <div class="icon error"></div>
        <div class="text">
          {{ waitHostErrorData.tips }}
        </div>
      </div>

      <div class="dialog-content">
        <div class="content-item">
          {{ $t('login.meetNumber') + waitHostErrorData.conferenceNo }}
        </div>
        <div class="content-item">
          {{ $t('login.meetTheme') + waitHostErrorData.title }}
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="waitHostDialog = false">
          {{ $t('login.leaveMeet')}}
        </el-button>
      </span>
    </el-dialog>

    <!-- 结束会议弹窗 -->
    <el-dialog
      custom-class="commonElDialog"
      class="dialogClass"
      :width = "$i18n.locale === 'en-US' ? '500px' : '380px'"
      :visible.sync="endMeetElDialog"
    >

      <div class="dialog-title">
        <div class="icon error"></div>
        <div class="text">{{ endMeetElDialogTitle }}</div>
      </div>

      <div class="dialog-content">
        <div class="content-item">
          {{ $t('login.meetNumber') + forceEndMeetInfo.conferenceNo }}
        </div>
        <div class="content-item">
          {{ $t('login.meetTheme') + forceEndMeetInfo.title }}
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="forceEndMeetHandle">{{$t('login.end')}}</el-button>
        <el-button @click="endMeetElDialog = false">{{$t('login.noEnd')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MeetingTopRightUploadLog from "../meeting/top/Meeting.Right.UploadLog.vue";
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";
import CryptoJS from "crypto-js";
import { mapGetters } from "vuex";
import Footer from "@/components/footer/Footer";
import Back from "@/components/back/Back";
import Lang from "@/components/lang/index";
import Book from "@/views/meetingManager/components/Book";
import Personal from "@/views/meetingManager/components/Personal";
import { getServiceUrl, helpUrl} from "@/utils/tool";
// import debounce from 'lodash.debounce'
import throttle from 'lodash.throttle'
import {THROTLE_TIME} from "@/constant/index";
import { formatAMPM } from "@/utils/dateFormat";
import { removeUserInfo, setAvatar, getAcceptLanguage, setMeetInfo } from "@/utils/auth";
import { LANG_ZH } from '@/constant/index'
import MeetingSettings from "@/views/meeting/Meeting.Settings.vue";
import { strToBoolean, strFormat  } from '@/utils/str'
import { checkSmallPwd  } from '@/utils/check'

import {
  fetchUserInfo,
  fetchRoomExport,
  exportRoomInfo,
  fetchConference,
  updateUserInfo,
  modifyPassword,
  fetchTodayList,
  fetchOrderList,
  fetchHistoryDetail,
  createConference,
  joinConference,
  updatePersonConference,
  updateConference,
  delConference,
  delMeetRoom
} from '@/api/meet'

import joinErrorMixins from '@/mixins/joinError.mixins'

export default {
  name: "Home",
  mixins: [joinErrorMixins],
  components: {
    Footer,
    Back,
    Book,
    Personal,
    MeetingSettings,
    Lang,
    meetingTopRightUploadLog: MeetingTopRightUploadLog
  },
  destroyed() {
    let that = this;
    clearInterval(that.timer);
  },
  computed: {
    ...mapGetters(["userId", "realName", "conferenceNo"]),
    meetingCode() {
      return this.$tools.formatCode(this.conferenceNo);
    },
    scrollDisabled() {
      return this.isLoading || !this.isRemain
    },
    scrollTodayDisabled() {
      return this.isLoadingToday || !this.isRemain
    },
  },
  data() {
    return {
      hover:false,
      isfocus:false,
      // 删除会议弹窗
      delMeetElDialog: false,
      delMeetElDialogText: '',
      delMeetInfo: {},
      delMeetType: 1, // 1: 主界面删除预约会议 2：预约界面删除预约会议 3：删除历史会议
      myCheckbox: true,
      loudspeaker: true,
      myCamera: false,
      userSelfNo:'',
      myGeRen: false,
      jumpCount: 0, // 底部跳转会议数量
      jumpTopCount: 0, // 顶部会议数量
      jumpText: "", // 文字
      meetingDetailId:"",
      activeMenu: "index",
      nowTime: "",
      nowCalendar: "",
      nowDate: "",
      meetingList: [],
      detailType: "personal",
      isShowDetail: true,
      meetingHistoryList: [],
      isRemain: true,
      pageIndex: 1,
      currentPageIndex: 1,
      currentRowId: 0,
      reservation: false, // 预约会议111
      showPersonal: false,
      appointment: true, // 是否是预约会议
      meetingDetail: "",
      panelIndex: "",
      userName: "", // 用户名称
      dateMap1: [], // 日期字典
      dateMap2: [],
      isLoading: false, // 是否加载loading
      isLoadingToday: false, // 今天会议列表loading
      dataNormalLoading: true, //数据正常加载标识 false=>数据定时更新启动 true 关闭
      currentListType:'',
      isShowPanel: false,
      rowIndex: 0, // 行Index
      userInfo: {}, // 用户新消息
      myUserName: {
        value: "",
        errorTip: "",
      },
      // 用户旧密码
      myUserPwd: {
        value: "",
        type: "password",
        errorTip: "",
      },
      // 用户新密码
      myUserNewPwd: {
        value: "",
        type: "password",
      },
      // 用户新密码再次
      myUserNewPwd2: {
        value: "",
        type: "password",
      },
      changeMeetDialog:false,
      UserPwdtDialog:false,
      password:false,// 会议密码
      ownerPassword:false,// 主持人密码
      // 会议密码
      huiyiPwd: {
        value: "",
        type: "password",
        errorTip: "",
      },
      //主持人密码
      huiyiOwnerPwd: {
        value: "",
        type: "password",
        errorTip: "",
      },
      allowEarlyEntry:true,// 允许成员在主持人入会前加入会议
      muteJoinMeeting:false, // 成员入会时自动静音
      timer: null,

      delMainMeetLoading: false,
      delReservationMeetLoading: false,
      delHistoryMeetLoading: false
    };
  },
  async created() {
    // 获取用户信息
    this.handleUserId();
    // 获取当前日期
    this.getTime();
  },
  watch: {
     pageIndex(idx) {
       this.currentPageIndex = idx > 1 ? --idx : idx
      //  console.log(`watch=>pageIndex=>currentPageIndex:${this.currentPageIndex}`)
     },
     dataNormalLoading(value) {
        // console.log(`watch=>dataNormalLoading:${value}`)
        this.timer && clearInterval(this.timer)
        if (!value) {
          const self = this
          this.timer = setInterval(function () {
            self.currentListType && self.mutiAsyncRequestMeeting(self.currentListType)
          }, 15000)
        }
     }
  },
  mounted() {
    let that = this;
    // 获取今日会议列表
      that.loadMeetingList('index');
      setInterval(function () {
        that.getTime();
      }, 1000);
    },
  methods: {
    onMouse(){
      this.isfocus = false;
      this.handleReservation('')
    },
    proving() {
      this.formData.bankName = this.formData.bankName.replace(/[^\.\d]/g,'');
      this.formData.bankName = this.formData.bankName.replace('.','');
    },
    formatAMPM,
    initCheckBoxs() {
        //从上次操作记忆中加载勾选状态
        let isUseHuaTong = this.$loadLocal("isUseHuaTong")
        this.myCheckbox = strToBoolean(isUseHuaTong, true)

        let isUseShiPin = this.$loadLocal("isUseShiPin")
        this.myCamera = strToBoolean(isUseShiPin, false)

        let isUseYangShengQi = this.$loadLocal("isUseYangShengQi")
        this.loudspeaker = strToBoolean(isUseYangShengQi, true)

        let isUseMyGeRen = this.$loadLocal(this.userInfo.userId + "isUseMyGeRen")
        this.myGeRen = strToBoolean(isUseMyGeRen, false)
    },
    myCode() {
      this.detailType = "personal";
      this.rowIndex = -1
      this.currentRowId = 0
    },
    service() {
      window.open(getServiceUrl());
    },
    help() {
      window.open(helpUrl);
    },
    onCopy(e) {
      this.$message.success(this.$t('seeting.copySuccess'))
    },
    // 复制失败时的回调函数
    onError(e) {},
    // 导出
    async onDownload(item) {
      try {
        const exportCode = await fetchRoomExport(item)

        const blobData = await exportRoomInfo({
          code: exportCode
        })

        this.$download(
          this.$t('seeting.meetList') + item.title + this.$getDate() + ")",
          blobData
        )
      } catch (error) {
        console.log(error) // TODO:
      }
    },
    onChangePwd(type) {
      let that = this;
      if (!that.password && type === 0) {
        that.huiyiPwd.value = "";
      }
      if (!that.ownerPassword && type === 1) {
        that.huiyiOwnerPwd.value = "";
      }
    },
    // 打开修改用户名称弹窗
    onEditUserName() {
      let that = this;
      that.myUserName.value = that.$store.state.user.realName
      const errorMsg = strFormat(that.$t('reg.regBetwwenLength'), '1', '25')
      this.$prompt('',that.$t('seeting.changeName'), {
          confirmButtonText: that.$t('login.sure'),
          cancelButtonText:  that.$t('login.cancel'),
          inputPlaceholder: that.$t('seeting.inputUserName'),
          inputValue:that.myUserName.value,
          inputPattern: /^.{1,25}$/,
          inputErrorMessage: errorMsg
        }).then(({ value }) => {
          let data = {
              nickname: value,
            };
            console.log(data,'90909090')

          updateUserInfo(data)
          .then(() => {
            this.$store.commit('user/SET_REALNAME', value)
            this.$message.success(that.$t('seeting.editSuess'))
          }).catch(() => {})

        }).catch(() => {})
    },
    // 打开修改用户密码弹窗
    onEditPwd() {
      let that = this;
      that.myUserPwd.value = ''
      that.myUserNewPwd.value = ''
      that.myUserNewPwd2.value = ''

      that.UserPwdtDialog = true;
      that.showPersonal = false;
    },
    // 打开修改会议信息弹窗
    onMore() {
      let that = this;
      that.huiyiPwd.errorTip = ""
      that.huiyiOwnerPwd.errorTip = ""
      that.changeMeetDialog = true;
      that.password = that.userInfo.passwordEnable === 1 ? true : false;
      that.huiyiPwd.value = that.userInfo.password;
      that.ownerPassword = that.userInfo.ownerPasswordEnable === 1 ? true : false;
      that.huiyiOwnerPwd.value = that.userInfo.ownerPassword;
      that.allowEarlyEntry = that.userInfo.allowEarlyEntry === 1 ? true : false;
      that.muteJoinMeeting = that.userInfo.muteJoinMeeting === 1 ? true : false;
      that.showPersonal = false;
    },


    // 主界面点击删除预约会议
    async deleteItem(item, type) {
      this.delMeetType = type

      try {
        const data = await fetchConference({
          id: item.id
        })

        const { status, title, roomId, conferenceNo } = data

        if (status === 10) { // 会议正在进行中，先进行结束会议的提示
          this.forceEndMeetInfo = {
            conferenceNo: conferenceNo,
            title: title,
            roomId: roomId,
            cb: () => {
              this.openDelDialog(item)
            }
          }

          this.endMeetElDialogTitle = this.$t('seeting.ifEnd')
          this.endMeetElDialog = true
        } else { // 执行删除会议
          this.openDelDialog(item)
        }
      } catch (error) {
        console.log(error)
      }
    },

     // 打开删除会议弹窗
    openDelDialog(item) {
      this.delMeetInfo = item
      this.delMeetElDialogText = this.$t('seeting.noDelete')
      this.delMeetElDialog = true
    },

    // 主界面删除预约会议
    async delMainMeetHandle() {
      try {
        this.delMainMeetLoading = true
        await delConference({
          id: this.delMeetInfo.id
        })
        this.delMainMeetLoading = false
        this.loadMeetingList('index')
        this.delMeetElDialog = false
      } catch (error) {
        this.delMainMeetLoading = false
        console.log(error)
      }
    },

    // 点击删除历史会议
    handleDel() {
      this.delMeetType = 3

      this.delMeetElDialogText = this.$t('seeting.Irrecoverable')
      this.delMeetElDialog = true
    },

    // 删除历史会议
    async delHistoryMeetHandle() {
      this.delHistoryMeetLoading = true
      try {
        await delMeetRoom({
          id: this.meetingDetail.rtcRoomNo
        })
        this.delHistoryMeetLoading = false
        this.detailType = "personal"
        this.loadMeetingList('history')

        this.delMeetElDialog = false
      } catch (error) {
        this.delHistoryMeetLoading = false
        console.log(error)
      }
    },


    // 预约界面删除预约会议
    handleCancelMeeting() {
      this.deleteItem({
        id: this.meetingDetail.id
      }, 2)
    },

    // 预约界面删除预约会议
    async delReservationMeetHandle() {
      this.delReservationMeetLoading = true
      try {
        await delConference({
          id: this.delMeetInfo.id
        })
        this.delReservationMeetLoading = false
        this.loadMeetingList('meeting')
        this.detailType = "personal"
        this.delMeetElDialog = false
      } catch (error) {
        this.delReservationMeetLoading = false
        console.log(error)
      }
    },
    // 修改密码
    async onAffirmUserPwd(isOk) {
      let that = this;
      if (isOk) {
        that.myUserPwd.errorTip = "";

        if(that.myUserPwd.value === '' || that.myUserNewPwd.value === '' || that.myUserNewPwd2.value === ''){
          return that.myUserPwd.errorTip = that.$t('reg.emptyPwd');
        }
         if(that.myUserPwd.value.length < 6 || that.myUserNewPwd.value.length < 8){
          return  that.myUserPwd.errorTip = that.$t('reg.lengthPwd');
        }
        if(that.myUserNewPwd.value !== that.myUserNewPwd2.value){
          return  that.myUserPwd.errorTip = that.$t('reg.somePwd');
        }
        that.UserPwdtDialog = false;
        let data = {};

        data.password = Base64.stringify(
          CryptoJS.enc.Utf8.parse(sha256(that.myUserPwd.value).toString())
        );
        data.newPassword = Base64.stringify(
          CryptoJS.enc.Utf8.parse(sha256(that.myUserNewPwd.value).toString())
        );
        data.username = that.userInfo.username;

        try {
          if (that.userInfo.enterpriseId > 0) {
             data.enterpriseId = that.userInfo.enterpriseId;
          }
          await modifyPassword(data, that.userInfo.enterpriseId > 0 ? 1 : 0)

          this.$message.success(that.$t('seeting.editPwdSuccess'))
        } catch (error) {
          console.log(error) // TODO:
        }
      } else {
        that.UserPwdtDialog = false;
      }
    },
    // 修改会议信息
    onAffirmMore:throttle(async function(isOk) { 
      let that = this;
      if (isOk) {
        if(that.password && !checkSmallPwd(that.huiyiPwd.value)){
            return that.huiyiPwd.errorTip = that.$t('reg.huiyiPwd')
        }
        if(that.ownerPassword && !checkSmallPwd(that.huiyiOwnerPwd.value)){
            return that.huiyiOwnerPwd.errorTip = that.$t('reg.huiyiPwd')
        }
        that.changeMeetDialog = false;
        let data = {};

        data.password = that.huiyiPwd.value; // 会议密码
        data.passwordEnable = that.password ? 1 : 0; // 是否启用密码
        data.ownerPassword = that.huiyiOwnerPwd.value; // 主持人密码
        data.ownerPasswordEnable = that.ownerPassword ? 1 : 0; // 是否启用主持人密码
        data.allowEarlyEntry = that.allowEarlyEntry ? 1 : 0; // 允许成员在主持人入会前加入会议
        data.muteJoinMeeting = that.muteJoinMeeting ? 1 : 0; // 成员入会时自动静音

        try {
          await updatePersonConference(that.userInfo.conferenceNo, data)

          this.$message.success(that.$t('seeting.editMeet'))
          that.userInfo.passwordEnable = data.passwordEnable;
          that.userInfo.password = data.password;
          that.userInfo.ownerPasswordEnable = data.ownerPasswordEnable;
          that.userInfo.ownerPassword = data.ownerPassword;
          that.userInfo.allowEarlyEntry = data.allowEarlyEntry;
          that.userInfo.muteJoinMeeting = data.muteJoinMeeting;
        } catch (error) {
          console.log(error) // TODO:
        }
      } else {
        that.changeMeetDialog = false;
      }
    }, THROTLE_TIME,{ leading: true, trailing: false}),
    // 今天会议列表 更多按钮
    handlePanel(item, index) {
      this.panelIndex = index;
    },
    // 关闭 今天会议列表更多
    closePanel() {
      this.panelIndex = "";
    },
    // 获取用户Id
    async handleUserId() {
      try {
        const data = await fetchUserInfo()

        this.userInfo = data;
        this.userSelfNo = this.$t('seeting.useSelf') + this.$tools.formatCode(this.userInfo.conferenceNo)
        this.$store.dispatch("user/getInfo", { // TODO: 之后去掉
          data
        })

        //设置会前 userId，上传日志时没有peerID的情况方便排查日志
        this.$configs.userId = this.userInfo.userId;

        // 存储头像
        setAvatar(this.userInfo.avatar)
        //初始化勾选项
        this.initCheckBoxs()
      } catch (error) {
        // TODO:
        console.log(error)
      }
    },
    // 过滤信息
    filterList() {
      let that = this;
      let currentDate = that.$getDateTime(true);
      let leftArray = []; // < 当前时间的会议(立即)
      let rightArray = []; // > 当前时间的会议(N分钟,即将召开)
      that.meetingList.forEach((item) => {
        item.time = that.$getLongTime(item.beginTime);
        item.statusText = that.getYuYueState(item);
        let startDate = item.beginTime;
        let compareValue = that.$compareDate(currentDate, startDate);
        let fenzhong = Math.ceil(compareValue / 1000 / 60);
        if (fenzhong < 0) {
          leftArray.push(item);
        } else {
          rightArray.push(item);
        }
      });

      // 有立即的会议
      if (leftArray.length > 0) {
        that.jumpTopCount = leftArray.length;
        that.jumpCount = rightArray.length;
        that.meetingList = leftArray;
        that.jumpText = that.$t('seeting.now')+ that.jumpTopCount + that.$t('seeting.countMeet');
      }
      // 没有立即的会议
      else {
        let minArray = [];
        let tempArray = [];
        rightArray.forEach((item) => {
          tempArray.push(item.time);
        });
        let minItem = Math.min.apply(null, tempArray);

        rightArray.forEach((item) => {
          if (item.time === minItem) {
            minArray.push(item);
          }
        });

        that.meetingList = minArray;
        let cusDate = that.$getLongTime(currentDate);
        let fenzhong = Math.ceil((minItem - cusDate) / 1000 / 60);

        that.jumpTopCount = minArray.length;
        that.jumpCount = rightArray.length - that.jumpTopCount;
       const langVal = getAcceptLanguage()
       if (fenzhong > 10) {
          that.jumpText = that.jumpTopCount + that.$t('seeting.countMeet');
        } else {
          if (fenzhong === 0) {
            that.jumpText = that.$t('seeting.now') + that.jumpTopCount + that.$t('seeting.countMeet');
          } else {
            let context =  that.jumpTopCount + that.$t('seeting.countMeet');
            that.jumpText =  langVal === LANG_ZH ? `${fenzhong} 分钟后开始 ${context}` :`in ${fenzhong} minutes ${context}`;
          }
        }
      }
    },
    // 顶部导航栏切换
    handleMenu(value) {
      let that = this;
      that.activeMenu = value;
      that.reservation = false;
      that.appointment = true;
      if (value === "meeting") {
        that.detailType = "personal";
        that.loadMeetingList('meeting');
      } else {
         that.loadMeetingList('index');
      }
    },
    // 预约会议 历史会议切换
    handleMeetingList(val) {
      let that = this;
      that.detailType = "personal";
      if (val === "history") {
        that.appointment = false;
        that.loadMeetingList('history');
      } else {
        that.appointment = true;
        that.loadMeetingList('meeting');
      }
    },
    setCurrentRowId(index) {
      if (index > -1 && this.meetingHistoryList[index]) {
          if (this.appointment && this.meetingHistoryList[index].id) {
            this.currentRowId = this.meetingHistoryList[index].id
            console.log(`setCurrentRowId=>rowIndex=>currentRowId:${this.currentRowId}`)
          }
          if (!this.appointment && this.meetingHistoryList[index].conferenceDetailId) {
            this.currentRowId = this.meetingHistoryList[index].conferenceDetailId
            console.log(`setCurrentRowId=>rowIndex=>currentRowId:${this.currentRowId}`)
          }
       }
    },

    //历史会议列表 预约会议列表 今日会议列表
    async loadMeetingList(type, isScrollLoad = false) {
      // console.log(`正常加载${type}数据start`)
      this.dataNormalLoading = true
      if (type) {
        this.currentListType = type
      } else {
        this.currentListType = this.appointment ? 'meeting' : 'history'
      }

      //首次加载 参数初始化
      if (!isScrollLoad) {
        this.initListParameters()
      }

      let data = {
        pageNum: this.pageIndex,
        pageSize: 50,
        param: {},
      }

      let fetchApi = () => {}
      if (type === 'index') {
        this.isLoadingToday = true;
        fetchApi = fetchTodayList
      }
      if (type === 'meeting' || (!type && this.appointment)) {
        this.isLoading = true;
        fetchApi = fetchOrderList
      }
      if (type === 'history' || (!type && !this.appointment)) {
        this.isLoading = true;
        fetchApi = fetchHistoryDetail
      }

      try {
        const resData = await fetchApi(data)

        const { total, records } = resData
        this.isLoadingToday = false
        this.isLoading = false
        if (total === 0) {
          this.meetingList = []
          this.meetingHistoryList = []
        }
        if (total > 0 && records.length > 0) {
           if (type === 'index') {
              let dataArray = this.pageIndex === 1 ? [] : this.meetingList
              this.meetingList = dataArray.concat(records)
              this.filterList()
           } else {
              let dataArray = this.pageIndex === 1 ? [] : this.meetingHistoryList
              this.meetingHistoryList = dataArray.concat(records)
           }
           this.isRemain = true
           this.pageIndex++
        } else {
           this.isRemain = false
        }

        if (type !== 'index') {
           //console.log(this.dateMap1)
           this.dateMap1 = []
           this.initDateMap(this.meetingHistoryList, this.dateMap1)

           if (this.meetingHistoryList.length > 0) {
             this.handleMeetingDetail(this.meetingHistoryList[this.rowIndex], this.rowIndex);
           } else {
             this.myCode()
           }
        }
        this.dataNormalLoading = false
      } catch (error) {
        console.log(error) // TODO:
        this.isLoadingToday = false
        this.isLoading = false
        this.dataNormalLoading = false
      }
    },
    //初始化列表数据参数（加载首页）
    initListParameters() {
      this.isRemain = true
      this.pageIndex = 1
      this.dateMap1 = []
      this.rowIndex = 0
    },
    //同步当前页之前的所有数据  更新
    async mutiAsyncRequestMeeting(type) {
      // console.log(`异步更新${type}数据start`)
      let fetchApi = () => {}
      if (type === 'index') {
        fetchApi = fetchTodayList
      }
      if (type === 'meeting') {
        fetchApi = fetchOrderList
      }
      if (type === 'history') {
        fetchApi = fetchHistoryDetail
      }

      const requestMeeting = async (pageIndex) => {
        let data = {
          pageNum: pageIndex,
          pageSize: 50,
          param: {}
        }

        try {
          const resData = await fetchApi(data)
          return Promise.resolve(resData.records || [])
        } catch (error) {
          throw new Error(error)
        }
      }
      let tempPageIndex = 1
      let tempPromiseArray = []
      console.log(`currentPageIndex=>${this.currentPageIndex}`)
      while(tempPageIndex <= this.currentPageIndex) {
         const result = []
         try {
           result = await requestMeeting(tempPageIndex)
         } catch (error) {
           console.error(error)
           this.isLoadingToday = false
           this.isLoading = false
           return
         }

         if (result.length === 0) {
            break
         }
         tempPromiseArray.push(result)
         tempPageIndex ++
      }
      if (type === 'index') {
        this.isLoadingToday = true
      } else {
        this.isLoading = true
      }
      // console.log(`tempPromiseArray=>${tempPromiseArray}`)
      // console.log(`tempPageIndex=>${tempPageIndex}`)
      Promise.all(tempPromiseArray).then(results => {
         this.isLoadingToday = false
         this.isLoading = false
         if (type === 'index') {
            this.meetingList = results.flat()
            this.filterList()
         } else {
            this.meetingHistoryList = results.flat()

            //console.log(this.dateMap1)
            this.dateMap1 = []
            this.initDateMap(this.meetingHistoryList, this.dateMap1)
            console.log(`currentRowId=>${this.currentRowId}`)
            if (this.meetingHistoryList.length > 0 && this.currentRowId) {
              const rowIndex = this.meetingHistoryList.findIndex(item => {
                 if (this.appointment && item.id) {
                    return item.id === this.currentRowId
                 }
                 if (!this.appointment && item.conferenceDetailId) {
                    return item.conferenceDetailId === this.currentRowId
                 }
              })
              //console.log(`对应rowIndex=>${rowIndex}`)
              if (rowIndex > -1) {
                 this.handleMeetingDetail(this.meetingHistoryList[rowIndex], rowIndex)
              }
            } else {
              this.myCode()
            }
         }
      }).catch(error => {
        this.isLoadingToday = false
        this.isLoading = false
        console.error(`同步数据出错=>${error}`)
      })
    },
    // 是否显示标题
    isShowTitle(time, idx) {
       if (this.reservation || !time) {
          return;
       }

       let beginTime = this.$dateFormat(time, "yyyy-MM-dd");
       if (this.dateMap1[idx] && this.dateMap1[idx][beginTime] === 1) {
          return true;
       } else {
         return false;
       }
    },
    // 获取显示标题时间
    getTitleDate(time) {
      let that = this;
      let date = time;
      if (!time) {
        return;
      }
      if (that.$dateFormat(date, "yyyy-MM-dd") === that.$getDate()) {
        date = that.$t('seeting.today')
      } else {
        date =
          that.$dateFormat(time, "yyyy-MM-dd") +
          " " +
          that.$getWeekWord(time);
      }
      return date;
    },
    // 获取入会时间
    getEnterDate(item) {
      let that = this;
      let date = "";
      // 预约会议 入会时间 业务
      if (that.appointment) {
        date =
          that.$dateFormat(item.beginTime, "hh:mm") +
          " - " +
          that.$dateFormat(item.endTime, "hh:mm");
      }
      // 历史会议 入会时间 业务
      else {
        date = that.$dateFormat(item.beginTime, "yyyy-MM-dd hh:mm");
      }
      return date;
    },
    // 获取预约详情状态
    getYuYueState(item) {
      let that = this;
      let status = item.status;
      let text = "";
      let currentDate = that.$getDateTime(true);
      let startDate = item.beginTime;
      let compareValue = that.$compareDate(currentDate, startDate);

      let currentDate2 = that.$getDate();
      let startDate2 = that.$dateFormat(item.beginTime, "yyyy-MM-dd");

      let compareValue2 = that.$compareDate(currentDate2, startDate2);

      let fenzhong = Math.ceil(compareValue / 1000 / 60);

      if (status === 0 || status === 20 || status === 40) {
        if (fenzhong < 0) {
          text = that.$t('seeting.immediately');
        } else if (fenzhong > 10) {
          if (compareValue2 > 0) {
            text = "";
          } else {
            text =  that.$t('seeting.imMeet');
          }
        } else if (fenzhong > 0 && fenzhong <= 10) {
          const langVal = getAcceptLanguage()
          text =  langVal === LANG_ZH ? `${fenzhong}分钟后开始`: `in ${fenzhong} minutes`;
          // console.log(text,'909090900')
        } else if (fenzhong === 0) {
          text =  that.$t('seeting.immediately');
        }
      } else if (status === 10) {
        text = that.$t('seeting.haveHand');
      } else if (status === 30) {
        text = that.$t('seeting.Cancelled');
      }
      return text;
    },
    //初始数据日期字典 首个相同日期值填充id 方便遍历展示
    initDateMap(list, dateMap = []) {
       for (let i = 0; i < list.length; i++) {
           const beginTime = this.$dateFormat(this.appointment ? list[i].beginTime : list[i].enterTime, "yyyy-MM-dd");
           const filters = dateMap.filter(item => { return item.hasOwnProperty(beginTime) })
           let obj = {}
           if (filters.length === 0) {
              obj[beginTime] = 1 //标记
           } else {
              obj[beginTime] = 0
           }
           dateMap.push(obj)
       }
    },
    // 会议详情
    handleMeetingDetail(item, index) {
      if(!item) return;
      this.rowIndex = index
      this.setCurrentRowId(index)
      this.detailType = "";
      if (this.appointment) {
        // 预约会议
        this.isShowDetail = true;
        console.log(`handleMeetingDetail=>id=>${item.id}`)
      } else {
        // 历史会议
        this.isShowDetail = false;
        console.log(`handleMeetingDetail=>id=>${item.conferenceDetailId}`)
      }
      this.meetingDetail = item;
      console.log(`handleMeetingDetail=>index=>${index}`)
    },


    // 预约会议
    handleReservation(id) {
      this.meetingDetailId = id;
      this.reservation = true;
    },

    // 返回
    handleBack() {
      this.reservation = false
    },
    // 个人信息
    handlePersonal() {
      this.showPersonal = !this.showPersonal;
    },
    // 关闭个人信息面板
    onClosePersonal(mouseup, mousedown) {
      this.showPersonal = false;
    },
    // 退出
    handleLoginOut() {
      this.showPersonal = false;
      removeUserInfo()
      this.$router.push({ path: "login" });
    },
    // 取消预约
    handleCancel() {
      this.reservation = false;
    },

    // 新会议
    handleJoinMeeting: throttle(function() {
      let data = {
        agenda: "", //会议议程
        agendaPermission: 0, // 会议议程权限 0：所有人可见 1：仅主持人可见
        allowEarlyEntry: 1, // 是否允许提前进入 1：是；0否；
        allowSelfUnmute: 1, // 成员加入会议静音 1：是；0否；
        beginTime: this.nowDate, // 开始时间
        endTime: this.nowDate, // 结束时间
        links: "", // 会议链接
        muteJoinMeeting: 1, // 允许成员自我解除静音;1是；0：否
        ownerId: "", // 会议主持人ID
        ownerPassword: "", // 主持人密码
        password: "", // 入会密码
        passwordEnable: 0, // 是否开启入会密码 0 关闭，默认；1 开启
        playTips: 0, // 成员加入会议时播放提示音1：是；0否；
        title: this.realName + (this.myGeRen? this.$t('seeting.personMeet') : this.$t('seeting.fastMeet')), // 会议名称
        type: 2, // 1:预约会议 2:即时会议
        useSelfConference: this.myGeRen? 1 : 0, // 是否使用个人会议号   1:是 0：否
      };
      this.handleSave(data)
    }, THROTLE_TIME,{ leading: true, trailing: false}),


    async handleSave(data, errorHandler) {
      try {
        const resData = await createConference(data)
        this.reservation = false;
        
        if (data.type === 2) {
          console.log('新建的会议信息')
          console.log(resData)
          this.handleJoin(resData.conferenceNo);
        } else {
          if (this.activeMenu==='index') {
            this.loadMeetingList('index')
          } else if (this.activeMenu==='meeting') {
            this.loadMeetingList(this.appointment ? 'meeting' : 'history')
          }
        }
      } catch (error) {
        errorHandler && errorHandler()
        this.handleEndMeetError(
          error,
          null,
          data
        )
        // if (error.context) { // TODO:
        //   error.context.commonElDialogTitle = data.type === 2 ? this.$t('seeting.failMeet') : this.$t('seeting.meetFail')
        // }
      }
    },


    // 更新预约
    async handleUpdate(data) {
      try {
        await updateConference(data)

        this.reservation = false;
        if (this.activeMenu === 'index') {
          this.loadMeetingList('index')
        } else if (this.activeMenu === 'meeting') {
          this.loadMeetingList('meeting')
        }
      } catch (error) {
        console.log(error) // TODO:
      }
    },
    // 列表开始会议
    handleBegin: throttle(function(item) {
      this.handleJoin(item.conferenceNo);
    },THROTLE_TIME,{ leading: true, trailing: false}),

    // 加入会议
    async handleJoin(conferenceNo) {
      console.error('---列表加入会议---')
      console.log(conferenceNo)

      let data = {
        conferenceNo: conferenceNo.replace(/\s+/g, ""),
        closeConference: true,
        userName: this.realName
      }

      if (this.passwordForm.pwd) {
        data.password = this.passwordForm.pwd
      }

      console.log(data)

      try {
        const resData = await joinConference(data)
        const { conference, userid, roomid } = resData
        this.$saveLocal("isUseHuaTong", this.myCheckbox);
        this.$saveLocal("isUseShiPin", this.myCamera);
        this.$saveLocal("isUseYangShengQi", this.loudspeaker);
        this.$saveLocal(userid + "isUseMyGeRen", this.myGeRen);

        // 入会失败的状态清理
        this.clearErrorState()

        setMeetInfo(
          resData["X-Conference-Token"],
          resData["X-Channel-Token"]
        )
        //是否开启H264High
		    let hp = window.location.search.substr(1).match(/hp=([^&]*)(&|$)/)
        let query =  {
            conferenceNo: conference.conferenceNo, // 会议号
            conferenceId: conference.id, // 会议id
            roomID: roomid, // 房间id
            userName: encodeURIComponent(this.realName) // 展示名
          }
        if(hp && hp[1]==="1"){
          query.hp = hp[1]
        }
        this.$router.push({
          name: "meeting",
          params: { userID: userid },
          query
        })
      } catch (error) {
        console.error('------join error--------')

        this.joinErrorHandle(error, conferenceNo)
      }
    },

    // 获取当前时间
    getTime() {
      let myDate = new Date();
      let myYear = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
      let myMonth = myDate.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      let myToday = myDate.getDate(); //获取当前日(1-31)
      let myDay = myDate.getDay(); //获取当前星期X(0-6,0代表星期天)
      let myHour = myDate.getHours(); //获取当前小时数(0-23)
      let myMinute = myDate.getMinutes(); //获取当前分钟数(0-59)
      let mySecond = myDate.getSeconds(); //获取当前秒数(0-59)
      let week = [
        this.$t('seeting.Sun'),
        this.$t('seeting.Mon'),
        this.$t('seeting.Tues'),
        this.$t('seeting.Wed'),
        this.$t('seeting.Thur'),
        this.$t('seeting.Fri'),
        this.$t('seeting.Sat'),
      ];
        this.nowCalendar =
        myYear + '-' +
        this.fillZero(myMonth) +'-' +
        this.fillZero(myToday) +
        "  " +
        week[myDay] +
        "";
      this.nowDate =
        myYear +
        "-" +
        this.fillZero(myMonth) +
        "-" +
        this.fillZero(myToday) +
        " " +
        this.fillZero(myHour) +
        ":" +
        this.fillZero(myMinute) +
        ":" +
        this.fillZero(mySecond);
      if (myHour <= 12) {
        myHour = this.$t('seeting.moring') + myHour;
      } else if (myHour > 12 && myHour < 24) {
        myHour -= 12;
        myHour =  this.$t('seeting.afternoon') + this.fillZero(myHour);
      }
      this.nowTime = myHour + ":" + this.fillZero(myMinute) + "";
    },
    // 点击按钮
    click() {
      let that = this;
      that.isShowPanel = !that.isShowPanel;
    },
    // 鼠标点击关闭面板
    onClose(mouseup, mousedown) {
      if (mousedown.target.className.baseVal === "icon16 layoutArrowIcon1") {
        return;
      }
      this.isShowPanel = false;
    },
    fillZero(str) {
      let realNum;
      if (str < 10) {
        realNum = "0" + str;
      } else {
        realNum = str;
      }
      return realNum;
    },
    // 显示设置面板
    openSetting() {
      this.$store.commit("isShowSettingPanel", true)
      this.$store.commit("settingPanelDefaultIndex", 0)
    },
  },
};
</script>

<style scoped lang="scss">
  @mixin btn-link(){
    ::v-deep .yb-button{
    margin:0 4px;
  }
  .btn_style{
    color:#1AB370;
    border-color: #1AB370;
    &:focus,&:hover{
        color: #1AB370;
        border: 1px solid #1AB370;
        background: rgba(26, 179, 112, 0.2);
    }
  }
  }
  @mixin font_pub($size,$weight,$line,$color){
    font-size: $size;
    font-weight: $weight;
    color: $color;
    line-height: $line;
  }
  @mixin time_style($margin,$size,$weight,$line,$color:none){
    @include font_pub($size,$weight,$line,$color);
    margin-top: $margin;
  }
  @mixin meeting_style($size,$color,$weight,$line){
    width: 418px;
    height: 36px;
    background: #ffffff;
    font-family: PingFangSC-Regular, PingFang SC;
    @include font_pub($size,$weight,$line,$color);
  }
  @mixin child_style($size,$color,$line,$weight){
    @include font_pub($size,$weight,$line,$color);
    text-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
  }
  @mixin public_meet($height,$size,$weight,$line,$color:none){
    @include font_pub($size,$weight,$line,$color);
    height: $height;
    font-family: PingFangSC-Regular, PingFang SC;
    }
  @mixin green_style($height,$size,$color,$margin,$weight,$line){
    @include public_meet($height,$size,$weight,$line,$color:none);
    font-family: PingFangSC-Medium, PingFang SC;
    text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    margin: $margin;
}
@mixin justi_style($jus){
  display: flex;
  justify-content: $jus;
}
  div::-webkit-scrollbar {
    width: 10px;
  }
.meeting-container-main {
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #dfdfdf;
  border-top: none;
  box-sizing: border-box;
  width: 420px;
}
.content-container {
  display: flex;
  flex-direction: column;

  .back {
    padding-left: 40px;
  }

  .head {
    display: flex;
    justify-content: space-between;
    height: 56px;
    width: 100%;
    background: #1ab370;
    //box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.1);
    .left {
      display: flex;
      padding-left: 40px;
      .logo {
        width: 40px;
        display: flex;
        align-items: center;

        img {
          width: 100%;
          vertical-align: middle;
          object-fit: cover;
        }
      }

      .title {
        margin-left: 10px;
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
        line-height: 56px;
      }
    }
    .menu {
      @include justi_style(center);
      align-items: center;
      margin-left:110px;
      .index,
      .meeting {
        cursor: pointer;
        width: 80px;
        height: 100%;
        text-align: center;
        line-height: 48px;
        border-bottom: 2px solid transparent;
        transition: all 0.2s ease;
        img {
          vertical-align: middle;
        }
      }
      .index-img{
        width:24px;
        height:24px;
      }

      .active {
        background: #ffffff;
        //background: rgba(0, 181, 61, 0.1);
        //border-bottom: 2px solid #00B53D;
      }
    }

    .personal-info {
      display: flex;
      padding-right: 40px;
      position: relative;

      .system {
        height: 100%;

        .system-list {
          display: flex;
          height: 100%;
          align-items: center;

          .system-item {
            font-size: 13px;
            font-weight: 400;
            color: #ffffff;
            line-height: 18px;
            margin-left: 24px;
            color: rgba(255, 255, 255, 0.85);
            cursor: pointer;
            &:hover,&:focus{
              color: rgba(255, 255, 255,1);
            }
            .myIcon {
              display: inline-block;
              vertical-align: middle;
              margin-right: 8px;
              opacity: 0.85;
              &:hover,&:focus{
                opacity: 1;
              }
            }
            //&:hover {
            //  color: #1AB370;
            //}
          }
        }
      }

      .avatar {
        width: 40px;
        margin-left: 40px;
        position: relative;
        padding-top: 10px;

        img {
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }

  .content {
    //padding-top: 54px;
    flex: 1;
    height: 100%;
    @include justi_style(center);
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;

    .meeting-list {
      display: flex;
      flex-direction: column;
      margin-top: 54px;
      margin-right: 200px;
      .meeting-bg {
        width: 420px;
        height: 110px;
        background-image:url(../../assets/images/xt.png);
        background-repeat:no-repeat;
	      background-size:100% 100%;
	      -moz-background-size:100% 100%;
        border-radius: 10px 10px 0px 0px;
        text-align: center;
        .xt_style{
          position: absolute;
          margin:10px 0 0 164px;
          width: 34px;
          height: 34px;
          opacity: 1;
          &:hover,&:focus{
            opacity: 0.85;
          }
        }
        .time {
          @include time_style(22px,36px,500,42px,#37a470)
        }
        .calendar {
          @include time_style(4px,14px,400,20px,#37a470)
        }
      }

      .meeting-container {
        width: 418px;
        height: 390px;
        background: #ffffff;
        border-top: none;
        overflow-y: auto;
        margin-bottom: 10px;
        .meeting-item-container {
          .meeting-item {
            background: #ffffff;
            transition: all 0.2s ease;
            padding: 15px;
            cursor: pointer;
            position: relative;
            height: 70px;

            .meeting-begin {
              @include justi_style(center);
              align-items: center;
              margin-top:16px;
              .more {
                width: 36px;
                height: 36px;
                position: relative;
                margin-right: 16px;

                .more_button-hover,
                .more_button-active {
                  position: absolute;
                  top: 0;
                  left: 0;
                  visibility: hidden;
                  opacity: 1;
                }

                &:hover .more_button-hover, &:active .more_button-active {
                  visibility: visible;
                  opacity: 1;
                }
              }
              .list-panel {
                position: absolute;
                top: 40px;
                width: 120px;
                background: #ffffff;
                box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
                border-radius: 8px;
                z-index: 1;
                .list-panel-container {
                  .list-panel-item {
                    font-size: 13px;
                    font-weight: 400;
                    padding: 7px 0 7px 16px;
                    color: #333333;
                    line-height: 18px;

                    &:hover {
                      background: rgba(0, 0, 0, 0.06);
                    }
                    &:last-child {
                      color: #ff4d4f;
                    }
                  }
                }
              }

              .begin-button {
                  border: 1px solid #1AB370;
                  font-size: 14px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #1AB370;
                  border-radius: 4px;
                  padding:10px;
                  &:hover{
                      background: rgba(26, 179, 112, 0.1);
                      color: #1AB370;
                    }
                  &:focus{
                    background: #1AB370;
                    color: #FFFFFF;
                    }
                }
            }

            .meeting-title {
              width:390px;
              height: 16px;
              font-size: 14px;
              font-weight: 600;
              color: #333333;
              line-height: 16px;
            }
            .meeting-time {
              font-size: 13px;
              font-weight: 400;
              color: #999999;
              line-height: 14px;
              margin-top: 12px;
              @include justi_style(space-between);
              .tips {
                font-size: 13px;
                font-weight: 400;
                color: #ff8400;
                line-height: 14px;
                margin-left: 10px;
              }
            }

            .meeting-code {
              font-size: 13px;
              font-weight: 400;
              color: #999999;
              line-height: 14px;
              margin-top: 10px;
            }

            .border {
              position: absolute;
              bottom: 0;
              height: 1px;
              width: 92%;
              background: rgba(0, 0, 0, 0.05);
            }

            &:hover {
              background: rgba(0, 0, 0, 0.04);
            }
          }
        }

        .tips {
          font-size: 16px;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
          text-align: center;
          margin-top: 174px;
        }
      }
    }

    .meeting-button {
      height: 480px;
      margin-top: 54px;
      flex-direction: column;
      align-items: center;
      @include justi_style(center);
      margin-right: 108px;

      .button-item:nth-child(2) {
        margin-top: 64px;
      }

      .button-item {
        display: flex;

        .button-group:nth-child(2) {
          margin-left: 64px;
        }

        .button-group {
          display: flex;
          width: 100px;
          height: 100px;
          flex-direction: column;
          text-align: center;
          position: relative;
          transition: all 0.2s ease;

          p {
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 16px;
            text-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
            margin-top: 16px;
          }
          .button-bg {
            width: 100%;
          }

          .button-bg-hover,
          .button-bg-active {
            position: absolute;
            top: 0;
            left: 0;
            visibility: hidden;
            opacity: 1;
          }

          &:hover .button-bg-hover {
            visibility: visible;
            opacity: 1;
          }

          &:hover .button-bg {
            visibility: hidden;
          }

          &:active .button-bg,
          &:active .button-bg-hover {
            visibility: hidden;
          }

          &:active .button-bg-active {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }

    .history {
      display: flex;
      flex-wrap: wrap;

      .history-list {
        box-sizing: border-box;
        position: relative;
        width: 434px;
        height: 593px;
        border-right: 1px solid rgba(0, 0, 0, 0.05);
        padding: 24px 24px 0 24px;

        .meeting-tab {
          @include justi_style(center);
          .tab-button {
            .appointment {
            width:120px;
              border-right: none;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }

            .history-button {
              width:120px;
              margin-left: 0;
              border-left: none;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }

          .meeting-add {
            box-sizing: border-box;
            text-align: left;
            padding-left: 43px;
            position: relative;
            transition: all 0.2s ease;
          }
        }
        .meeting-code {
          margin-top: 28px;
          text-align: center;

          ul {
            background: #f5f5f5;
            padding: 10px 0;
            box-sizing: border-box;

            li {
              &:first-child {
                @include child_style(24px,#333333,20px,400);
                font-size: 24px;
                font-family: PingFangSC-Regular, PingFang SC;
              }
              &:last-child {
                @include child_style(14px,#a6a6a6,20px,400);
                margin-top: 4px;
              }
            }
          }
        }
        .meeting-history-list {
          overflow-y: auto;
          height: 100%;
          .meeting-info {
            .date {
              box-sizing: border-box;
              padding: 10px 0;
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
              text-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
            }
            .meeting-detail {
              height: 98px;
              box-sizing: border-box;
              padding: 13px 0 11px 16px;
              transition: all 0.2s ease;
              cursor: pointer;
              color: #999;
              border-radius: 4px;
              &:hover {
                background: rgba(0, 0, 0, 0.04);
              }
            }
            .current {
              background: rgba(26, 179, 112, 0.3) !important;
              color: #000 !important;
            }
          }
          .meeting-title {
            @include child_style(16px,#333333,18px,600)
          }
          .infoItem {
            margin-top: 4px;
            clear: both;
          }

          .meeting-date {
            width: 230px;
            @include public_meet(16px,14px,400,16px);
            font-family: PingFangSC-Regular, PingFang SC;
            margin-top: 16px;
          }
          .meeting-owner {
            @include public_meet(16px,14px,400,16px);
            font-family: PingFangSC-Regular, PingFang SC;
            margin-top: 16px;
          }
          .meeting-ren {
            @include public_meet(16px,14px,400,16px);
            font-family: PingFangSC-Regular, PingFang SC;
            width: 142px;
            margin:8px 0 11px 30px;
            float: left;
          }

        }

        .history-no {
          @include child_style(16px,#666666,22px,400);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .history-detail {
        box-sizing: border-box;
        padding: 80px 64px 0 40px;
        width: 526px;
        height: 593px;

        .title {
          @include child_style(24px,#333333,30px,500)
        }

        .personal-info {
          .meeting-code {
            @include child_style(16px,#666666,22px,400);
            margin: 40px 0 40px 0;
          }
          .button-link{
            @include btn-link;
          }
        }

        .meeting-info {
          .subscribe {
            .subscribe-item {
             @include child_style(16px,#666666,22px,400);
              margin-top: 16px;
            }

            .time {
              margin-top: 37px;

              .status {
                color: #ff8200;
                margin-left: 25px;
              }
            }

            .button-link {
              margin-top: 32px;
              @include btn-link;
            .red_style{
              color:#FF5B5B;
              border-color: #FF5B5B;
              background: #FFFFFF;
              &:focus,&:hover{
                  color: #FF5B5B;
                  border: 1px solid #FF5B5B;
                  background: rgba(255, 77, 79, 0.2);
              }
            }
            }
          }

          .history {
            .history-item {
              margin-top: 18px;
              @include justi_style(space-between);
              width: 100%;
              font-size: 16px;
              font-weight: 400;
              color: #666666;
              line-height: 22px;
              &:first-child {
                margin-top: 37px;
              }

              .export {
                font-size: 16px;
                font-weight: 400;
                color: #1ab370;
                line-height: 22px;
                cursor: pointer;
              }

              .history-item-left {
                flex-shrink: 0;
              }

              .history-item-right {
                // flex-shrink: 0;
                word-break: break-all;
              }
            }

            .button-link {
              margin-top: 40px;
            }

            .history-item.border {
              margin: 29px 0 11px 0;
              height: 1px;
              background: rgba(0, 0, 0, 0.05);
            }
          }
        }
      }
    }
  }
}

.meeting-detail-code {
  @include time_style(8px,14px,400,16px);
  margin-bottom: 11px;
  float: left;
}
.history-list .nothingYuYue {
  @include time_style(160px,16px,400,16px,#666666);
  text-align: center;
  height:16px;
}

.meeting-detail1 {
  width: 250px;
  float: left;
}

.content-container .myUserName {
  margin-top: 30px;
  margin-left: 24px;
}

.errorTip2 {
  margin-top: 10px;
  margin-left: 40px;
}
.input_pwd{
  height:36px;
  margin:10px auto;
}

.updateMoreTitle {
  @include green_style(28px,20px,#333333,4px 0 0 24px,500,28px)
}

.gerenTitle {
  @include green_style(18px,13px,#999999,36px 0 0 24px,500,18px)
}

.gerenValue {
  width: 140px;
  @include green_style(22px,16px,#333333,4px 0 0 24px,500,22px,)
}

.huiyiPwdTitle {
  @include green_style(22px,16px,#333333,34px 0 16px 24px,500,22px)
}
.huiyiItemWrap {
  display:flex;
  flex-direction: column;
}
.huiyiItem {
  @mixin huiyi_style(){
    height: 36px;
    line-height: 36px;
    margin: 0 0 0 24px;
  }
  ::v-deep .el-checkbox,
  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #606266;
    font-weight: 300;
  }

  &:hover{
    background: rgba(0, 0, 0, 0.04);
  }
  .huiyiPwdCheck {
    @include huiyi_style;
    float: left;
  }
  .huiyiCheck2 {
    @include huiyi_style;
  }
  .input_style {
    float: left;
    width:160px;
    ::v-deep .el-input__inner {
      height: 30px;
      font-size: 12px;
      margin-top: 4px;
    }
    el-input__suffix-inner {
      display: inline-block;
      margin-top: -6px;
    }
  }
  .input_style.pwd {
    margin-left:52px;
  }
  .input_style.ownerPwd {
    margin-left:40px;
  }
}
.meeting-jump-top {
  @include meeting_style(16px,#333333,400,36px);
  text-indent: 16px;
}

.meeting-jump {
  text-align: center;
  border-radius: 0px 0px 10px 10px;
  cursor: pointer;
  @include meeting_style(14px,#999999,400,36px);
}

.hisTitleB {
  @include public_meet(22px,16px,400,22px);
  color: #999999;
  margin-top: 10px;
}

.iconhuiyixinxi {
  height: 24px;
  line-height: 24px;
  margin-top: 5px;
  cursor: pointer;
}

.wrapButItem {
  position: relative;
}

.selectPanel {
  height: 152px;
  background: #ffffff;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  position: absolute;
  top: 132px;
  left: 74px;
  z-index: 9;
}

.arrowIcon1 {
  width: 16px;
  margin: 5px 0 4px 8px;
  transition: all ease-out 0.3s;
  transform: rotate(0);
  position: absolute;
  left: 64px;
  top: 110px;
  cursor: pointer;
}

.arrowRotate {
  transform: rotate(-180deg);
}

.cbItem2 {
  margin: 11px 11px 0 16px;
  height: 20px;
}
::v-deep .el-checkbox__label,
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #606266;
  cursor: pointer;
  margin: 11px 0 0 0;
}
.top-bar-avatar{
  width: 24px !important;
  height: 24px;
  border-radius: 100%;
}
 .title2 {
    font-size: 24px;
    font-weight: 500;
    color: #333333;
    line-height: 56px;
  }
</style>
