<template>
  <div class="personal-container">
    <div class="personal-avatar">
      <span class="avatar">
        <img v-if="userInfo.avatar" :src="userInfo.avatar" />
        <my-icon v-else class="iconhuiyixinxi" :iconName="'icontouxiang_40_lv'" :fontSize="44"></my-icon>
      </span>
      <span class="userName" v-extend-max-length="[11, '...', userName]"></span>
      <my-icon
        class="edit-icon"
        :iconName="isOver ? 'icongerenshezhi_bianji_24_lv' : 'icongerenshezhi_bianji_24_hui'"
        :fontSize="24"
        @mouseover="mouseover"
        @mouseout="mouseout"
        @click="onEdit"
      ></my-icon>
    </div>
    <div class="meeting-info">
      <span class="title">{{$t('login.selfNumber')}}</span>
      <span class="meeting-code">{{$tools.formatCode(userInfo.conferenceNo)}}</span>
      <span
      class='more'
        @click="onMore"
      >{{$t('login.more')}}</span>
    </div>
    <div class="versions">
      <!-- 企业版 -->
      <div class="item item2"  v-if="userInfo.packageType===2">
        <span class="version-type" :title="$t('login.edition')" >
          <my-icon class="left" :iconName="'icongerenshezhi_qiyeban_beijing_lv'" :fontSize="82"></my-icon>
          <div class="version-type-title" :title="$t('login.edition')">{{$t('login.edition')}}</div>
        </span>
        <span class="version-name">{{$t('login.ybkj')}}</span>
      </div>
      <!-- 个人版 -->
      <div class="item item2" v-else>
        <div class="version-type version-typegeren">
          <my-icon class="left" :iconName="'icongerenshezhi_gerenban_beijing_hui'" :fontSize="82"></my-icon>
          <div class="version-type-title" :title="$t('login.free')">{{$t('login.free')}}</div>
        </div>
        <div class="version-name version-namegeren" :title="$t('login.callService')">{{$t('login.callService')}}</div>
      </div>
      <div class="item item2 display_style">
        <div class="version-title">{{$t('login.singleTime')}}</div>
        <!-- packageType：1免费版--时长，2企业版--不限、-->
        <div class="version-val">{{userInfo.packageType=== 1 ? userInfo.usefulLife + $t('login.mintue'): $t('login.unlimited')}}</div>
      </div>
      <div class="item item2 item3 display_style">
        <div class="version-title">{{$t('login.maxNumber')}}</div>
        <div class="version-val">{{userInfo.thresholdValue}}</div>
      </div>
    </div>
    <div class="personal-item">
      <span class="personal-value">{{userInfo.authRoles && userInfo.authRoles[0]==='0'? $t('login.phone'): $t('login.mailbox')}}</span>
      <span class="personal-value">{{userInfo.authRoles && userInfo.authRoles[0]==='0'? userInfo.phone: userInfo.email}}</span>
    </div>
    <div class="personal-item">
      <span class="personal-value">{{$t('login.password')}}</span>
      <span
        class='personal-value2'
        @click="onEditPwd"
      >{{$t('login.modify')}}</span>
    </div>
    <div class="personal-item">
      <span class="personal-value">{{$t('login.system')}}</span>
      <span
      class='personal-value2'
      >
        <a
          :href="`${adminUrl}/login`"
          target="_blank"
        >{{$t('login.goto')}}</a>
      </span>
    </div>
    <div class="personal-item">
      <span class="personal-value quit" @click="handleLoginOut">{{$t('login.logOut')}}</span>
    </div>
  </div>
</template>

<script>
import { adminUrl } from '@/config/index';

export default {
  name: "Personal",
  props: {
    userInfo: { default: {} },
  },
  computed: {
    userName() {
       return this.$store.state.user.realName
    }
  },
  data() {
    return {
      isOver: false,
      adminUrl
    };
  },
  methods: {
    // 鼠标经过编辑图标，icon需使用鼠标事件
    mouseover() {
      this.isOver = true;
    },
    // 鼠标离开编辑图标
    mouseout() {
      this.isOver = false;
    },
    // 退出登录
    handleLoginOut() {
      this.$emit("handleLoginOut");
    },
    // 更多按钮
    onMore() {
      let that = this;
      that.$emit("onMore");
    },
    // 编辑按钮
    onEdit() {
      let that = this;
      that.$emit("onEditUserName");
    },
    // 编辑密码
    onEditPwd() {
      let that = this;
      that.$emit("onEditPwd");
    },
  },
};
</script>

<style scoped lang="scss">
   @mixin name_style($size,$color){
    font-size: $size;
    font-weight: 400;
    color: $color;
    text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }
  @mixin public_style($size,$weight,$color,$line){
    @include name_style($size,$color);
    line-height: 18px;
  }
  @mixin public_value($size,$weight,$color,$line){
    @include public_style($size,$weight,$color,$line);
    cursor: pointer;
    padding: 9px 8px;
    a {
        color: $color;
      }
  }
  @mixin jus_style(){
    display: flex;
    justify-content: space-between;
  }
  @mixin dis_style($p){
    @include jus_style;
    box-sizing: border-box;
    padding: $p;
    align-items: center;
  }
  @mixin base_style($width,$height,$size,$weight,$color,$line){
    @include public_style($size,$weight,$color,$line);
    width: $width;
    height:$height;
  }

.personal-container {
  width: 320px;
  height: 414px;
  background: #ffffff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  position: absolute;
  top: 56px;
  right: 0px;
  box-sizing: border-box;
  padding-bottom: 16px;
  z-index: 1;
  .personal-avatar {
    @include dis_style(20px 24px);
    img {
      display: block;
      height: 100%;
      vertical-align: middle;
    }

    .userName {
      @include name_style(16px,#333);
      flex: 1;
      height: 100%;
      box-sizing: border-box;
      padding-left: 10px;
    }
    .edit-icon {
      cursor: pointer;
      margin-top: 10px;
    }
  }
  .meeting-info {
    @include dis_style(11px 24px);
    .title {
      @include name_style(13px,#666);
      line-height: 18px;
    }
    .meeting-code {
      @include name_style(13px,#333);
      flex: 1 1 auto;
      text-align: right;
      padding-right: 16px;
      line-height: 18px;
    }
    .more {
      @include public_style(13px,400,#666666,18px);
      cursor: pointer;
      color: #666666;
      &:hover,&:focus{
        color: #1ab370;
      }
    }
  }
  .versions {
    padding: 0 24px;
    .versions-item {
      border: 1px solid #e5e5e5;
      height: 36px;
      line-height: 36px;
      .version-type {
        @include base_style(82px,36px,14px,500,#ffffff,36px);
      }
      .version-name {
        @include base_style(189px,36px,13px,400,#1ab370,18px);
      }

      .version-value {
        @include public_style(13px,400,#666,18px);
      }
      &:first-child {
        background: rgba(26, 179, 112, 0.2);
        border-radius: 4px 4px 0px 0px;
      }
      &:nth-child(2) {
        border-top: none;
      }
      &:last-child {
        border-radius: 0px 0px 4px 4px;
        border-top: none;
      }
    }
    .versions-enterprise:before {
      content: "";
      border-left: 0px solid transparent;
      border-bottom: 38px solid #1ab370;
      border-right: 20px solid transparent;
      position: absolute;
      z-index: -1;
      width: 75px;
      top: 0;
      left: 0;
    }
  }
  .personal-item {
    @include jus_style;
    padding: 0 24px;
    transition: all 0.2s ease;
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
    .personal-value,.personal-value2 {
      @include public_value(13px,400,#666666,18px);
    }
    .personal-value2{
        &:hover,&:focus{
          color: #1ab370;
        a {
          color: #1ab370;
        }
        }
    }
    .personal-value.quit {
      color: #ff4d4f;
    }
  }
}

.versions {
  .item {
  width: 271px;
  height: 36px;
  line-height: 36px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.item2 {
  border-bottom: none;
}
.item3 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.display_style{
  @include jus_style;
}
.version-type {
  width: 82px;
  height: 36px;
  float: left;
  color: #fff;
  background: rgba(26, 179, 112, 0.1);
  font-size: 14px;
  position: relative;
    .version-type-title {
    position: absolute;
    left: 10px;
    width: 60px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap
  }
}
.version-name {
  background: rgba(26, 179, 112, 0.1);
  width: 179px;
  float: left;
  text-align: right;
  padding-right: 10px;
  color: rgba(26, 179, 112, 1);
}
.version-namegeren {
  overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
  color: #666666;
  background: rgba(0, 0, 0, 0.06);
  font-size: 12px;
}
.version-typegeren {
  background: rgba(0, 0, 0, 0.06);
}
.version-title,.version-val {
  height: 36px;
  line-height: 36px;
  float: left;
  color: #666666;
  font-size: 13px;
  text-align: center;
  margin-left:10px;
}
.version-val {
  text-align: right;
  padding-right: 10px;
  }
}

.avatar img {
  width: 44px;
  height: 44px !important;
  border-radius: 100%;
}
</style>